// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./image/OIG.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` body{
    /* The background image is set to a local image file called OIG.jpg. 
      The image will not repeat and will be fixed in position, ensuring that the background image remains static even when scrolling. 
      The background-size property is set to 'cover' which means the background image will be resized to cover the entire area, while maintaining its aspect ratio. */
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-attachment: fixed;  
    background-size: cover;
  }`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"CAAC;IACG;;qKAEiK;IACjK,yDAAwC;IACxC,4BAA4B;IAC5B,4BAA4B;IAC5B,sBAAsB;EACxB","sourcesContent":[" body{\n    /* The background image is set to a local image file called OIG.jpg. \n      The image will not repeat and will be fixed in position, ensuring that the background image remains static even when scrolling. \n      The background-size property is set to 'cover' which means the background image will be resized to cover the entire area, while maintaining its aspect ratio. */\n    background-image: url('./image/OIG.jpg');\n    background-repeat: no-repeat;\n    background-attachment: fixed;  \n    background-size: cover;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
